/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom"
import React, { useContext, useState, useEffect, useRef } from 'react';
import { GlobalContext } from './contexts/GlobalContext';
import { fetchUsers, updateProjects, fetchProjects, fetchRoles, addProjectUser, deleteProjectUser, fetchUsersProjectsRoles, uploadMasterTemplate, fetchMasterTemplateNames, fetchMasterTemplate } from '../Queries'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, Container, Col, Row, Button, Table, OverlayTrigger, Tooltip, Spinner } from "react-bootstrap";
import { Loading } from './Loading';
import { useNotifications } from '@de/react-bootstrap-notifier';
import fileDownload from 'js-file-download'

export function UserConfiguration() {
    const navigate = useNavigate()

    const { user } = useContext(GlobalContext);
    const { addInfo, addError } = useNotifications();
    const [inputUserData, setInputUserData] = useState(null);
    const [search, setSearch] = useState('');
    const [validate, setValidate] = useState(false);
    const [selectedMasterTemplate, setSelectedMasterTemplate] = useState(null);
    const templateFileToUpload = useRef(null);

    const queryClient = useQueryClient()
    const rolesResult = useQuery({ queryKey: ['roles'], queryFn: fetchRoles })
    const usersResult = useQuery({ queryKey: ['fetchUsers'], queryFn: () => fetchUsers() })
    const userProjectRolesResult = useQuery({ queryKey: ['fetchUsersProjectRoles'], queryFn: () => fetchUsersProjectsRoles() })
    const projectsResult = useQuery({ queryKey: ['projects'], queryFn: () => fetchProjects() })
    const masterTemplateNamesResult = useQuery({ queryKey: ['fetchMasterTemplateNames'], queryFn: () => fetchMasterTemplateNames() })

    const masterTemplateResult = useQuery({
        queryKey: ['fetchMasterTemplate', selectedMasterTemplate],
        queryFn: async () => {
            try {
                const blob = await fetchMasterTemplate(selectedMasterTemplate);
                fileDownload(blob, selectedMasterTemplate);
            } catch (error) {
                addError({ ...error.response.data, message: `An error occurred while downloading the file: "${selectedMasterTemplate}"!` })
            }
        },
        enabled: false
    })

    const uploadMasterTemplateResult = useMutation({
        mutationFn: async (formData) => await uploadMasterTemplate(formData),
        onSuccess: () => {
            addInfo("Master template updated");
            queryClient.invalidateQueries({ queryKey: ['fetchMasterTemplateNames'] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while uploading master template!" })
    })

    const updateProjectsMutation = useMutation({
        mutationFn: async () => await updateProjects(),
        onSuccess: () => {
            addInfo("Projects updated");
            queryClient.invalidateQueries({ queryKey: ['projects'] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while updating projects!" })
    })

    const addProjectUserMutation = useMutation({
        mutationFn: (input) => addProjectUser(input),
        onSuccess: () => {
            addInfo(`User added successfully`);
            queryClient.invalidateQueries({ queryKey: ['fetchUsersProjectRoles'] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while adding user!" })
    })

    const deleteProjectUserMutation = useMutation({
        mutationFn: async (input) => await deleteProjectUser(input),
        onSuccess: () => {
            let removedUser = userProjectRolesResult.data.find(u => u.user.id === deleteProjectUserMutation.variables.userId)
            addInfo(`${removedUser?.user?.username}  removed successfully`)
            queryClient.invalidateQueries({ queryKey: ['fetchUsersProjectRoles'] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while deleting user!" })
    })

    const handleAddUser = () => {
        setValidate(true);
        if (!!inputUserData?.userId && !!inputUserData?.projectId && !!inputUserData?.roleId)
            addProjectUserMutation.mutate({ userId: inputUserData.userId, projectId: inputUserData.projectId, roleId: inputUserData.roleId, IsAdmin: true });
    }

    const handleTemplateChange = (e) => {
        templateFileToUpload.current = e.target.files[0];
    }

    const handleTemplateUpload = () => {
        if (templateFileToUpload.current) {
            const blobData = new Blob([templateFileToUpload.current], { type: templateFileToUpload.current.type })
            const filename = templateFileToUpload.current.name

            let formData = new FormData();

            formData.append("fileForm", blobData, filename);

            uploadMasterTemplateResult.mutate(formData);
        }
    }

    useEffect(() => {
        if (!user?.isAdmin)
            navigate("/")
    }, [user]);

    return (
        <>
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    <Form.Label as={Col} className="mr-sm-2 h3 fw-bold text-center"
                        xs={12}
                        sm={12}
                        md={12}
                        xl={6}
                        xxl={6}
                    >
                        <div>
                            Master Template
                        </div>
                        <hr />
                    </Form.Label>
                </Row>
                <Row className="mb-5 d-flex flex-row justify-content-center">
                    <Col className="justify-content-center"
                        xs={12}
                        sm={12}
                        md={12}
                        xl={6}
                        xxl={6}>
                        <Row>
                            <Form.Group as={Row} controlId="formFile">
                                <Form.Label column sm="4" className="flex fw-bold d-flex align-items-center">
                                    Add master template
                                </Form.Label>
                                <Col sm="8">
                                    <div className="hstack">
                                        <Form.Control type="file" accept=".docx" onChange={handleTemplateChange} />
                                        <Button variant="outline-dark border-0"
                                            onClick={handleTemplateUpload}
                                            disabled={uploadMasterTemplateResult.isLoading}
                                        >
                                            {uploadMasterTemplateResult.isLoading
                                                ? <Spinner animation="border" />
                                                : <i className="fs-4 bi-file-earmark-arrow-up"></i>}
                                        </Button>
                                    </div>
                                </Col>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Form.Group as={Row} controlId="formFile">
                                <Form.Label column sm="4" className="flex fw-bold d-flex align-items-center">
                                    Download master template
                                </Form.Label>
                                <Col sm="8">
                                    <div className="hstack">
                                        <Form.Select
                                            aria-label="Download master template"
                                            placeholder="Select..."
                                            onChange={(e) => { setSelectedMasterTemplate(e.target.value) }}
                                            value={selectedMasterTemplate}
                                        >
                                            <option value="">Select...</option>
                                            {masterTemplateNamesResult?.data?.map((masterTemplate, index) => (
                                                <option key={index} value={masterTemplate} >
                                                    {masterTemplate.substring(0, masterTemplate.lastIndexOf('.'))}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <Button sm="1" variant="outline-dark border-0"
                                            onClick={() => { masterTemplateResult.refetch() }}
                                            disabled={!selectedMasterTemplate || masterTemplateResult.isFetching}
                                        >
                                            {masterTemplateResult.isFetching 
                                                ? <Spinner animation="border" />
                                                : <i className="fs-4 bi-file-earmark-arrow-down" />}
                                        </Button>
                                    </div>
                                </Col>
                            </Form.Group>
                        </Row>
                    </Col>
                </Row>

                <Row className="d-flex flex-row justify-content-center">
                    <Form.Label as={Col} className="mr-sm-2 h3 fw-bold text-center"
                        xs={12}
                        sm={12}
                        md={12}
                        xl={6}
                        xxl={6}
                    >
                        <div>
                            Users Configuration
                        </div>
                        <hr />
                    </Form.Label>
                </Row>

                <Row className="d-flex flex-row justify-content-center">
                    <Col className="justify-content-center"
                        xs={12}
                        sm={12}
                        md={12}
                        xl={6}
                        xxl={6}>
                        <Form>
                            <Row>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="mr-sm-2 fw-bold">User</Form.Label>
                                        <Form.Select
                                            id="user"
                                            value={inputUserData?.userId ?? 'DEFAULT'}
                                            onChange={(e) => { setInputUserData({ ...inputUserData, userId: e.target.value }) }}
                                            isInvalid={validate && !inputUserData?.userId}
                                        >
                                            <option value="DEFAULT" disabled>Choose...</option>
                                            {usersResult?.data?.map((user) => (
                                                <option key={user.id} value={user.id}>
                                                    {user.username}
                                                </option>))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Select user
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group>
                                        <Form.Label className="mr-sm-2 fw-bold">Role</Form.Label>
                                        <Form.Select
                                            id="role"
                                            value={inputUserData?.roleId ?? 'DEFAULT'}
                                            isInvalid={validate && !inputUserData?.roleId}
                                            onChange={(e) => { setInputUserData({ ...inputUserData, roleId: e.target.value }) }}
                                        >
                                            <option value="DEFAULT" disabled>Choose...</option>
                                            {rolesResult?.data?.filter(r => r.name !== 'None')?.map((role) => (
                                                <option key={role.id} value={role.id}>
                                                    {role.name}
                                                </option>))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Select role
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-2 mr-sm-2 mb-sm-0" >
                                        <Form.Label className="mr-sm-2 fw-bold">Project</Form.Label>
                                        <div className="hstack">
                                            {projectsResult?.isLoading
                                                ? <Loading />
                                                : <div>
                                                    <Form.Select
                                                        id="project"
                                                        value={inputUserData?.projectId ?? 'DEFAULT'}
                                                        isInvalid={validate && !inputUserData?.projectId}
                                                        onChange={(e) => { setInputUserData({ ...inputUserData, projectId: e.target.value }) }}
                                                    >
                                                        <option value="DEFAULT" disabled>Choose...</option>
                                                        {projectsResult?.data?.map((project) => (
                                                            <option key={project.id} value={project.id}>
                                                                {project.name}
                                                            </option>))}
                                                    </Form.Select>
                                                    <Form.Control.Feedback type="invalid">
                                                        Select project
                                                    </Form.Control.Feedback>
                                                </div>}

                                            <OverlayTrigger placement="top" overlay={<Tooltip id="update-projects">Update projects from Simapro</Tooltip>}>
                                                <Button
                                                    onClick={() => updateProjectsMutation.mutate()}
                                                    variant="outline-dark"
                                                    className="border-0 control-button" aria-label="refresh" aria-hidden="true"
                                                    color="dark"
                                                >
                                                    <i className="bi-arrow-clockwise"></i>
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Form.Group className="mb-2 mr-sm-2 mb-sm-0 mt-3">
                                <Button
                                    onClick={handleAddUser}
                                    variant="outline-dark"
                                    className="rounded-pill w-100 border-1"
                                    color="dark"
                                >
                                    Add user
                                </Button>
                            </Form.Group>
                        </Form>

                        <Form className="mt-5">
                            <Form.Group as={Col} controlId="projectName">
                                <Form.Control
                                    className="border-1 text-dark search"
                                    required
                                    type="text"
                                    value={search}
                                    placeholder='Search for ...'
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                        {userProjectRolesResult.isLoading || !userProjectRolesResult?.data
                            ? <Loading />
                            : <Table className="mt-1" variant="light">
                                <thead>
                                    <tr>
                                        <th> User </th>
                                        <th> Project </th>
                                        <th> Role </th>
                                        <th> Delete </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {userProjectRolesResult.data.filter(upr => upr?.user?.username?.toLowerCase().includes(search.toLowerCase()) || upr?.project?.name?.toLowerCase().includes(search.toLowerCase())).map((upr) => (
                                        <tr key={`${upr?.user?.id}-${upr?.project?.id}`}>
                                            <td>{upr?.user?.username}</td>
                                            <td>{upr?.project?.name}</td>
                                            <td>{upr?.role?.name}</td>
                                            <td>
                                                {upr?.username !== "admin_EPD" &&
                                                    <Button variant="outline-dark" className="border-0 control-button" aria-label="Delete" aria-hidden="true" onClick={() => deleteProjectUserMutation.mutate({ userId: upr.user.id, projectId: upr.project.id })}>
                                                        <i className="bi-trash3"></i>
                                                    </Button>}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>}
                    </Col>
                </Row>
            </Container>
        </>
    );
}